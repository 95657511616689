<template>
    <item-list-view
        :api="api"
        :headers="headers"
        id-key="uuid"
        title="Вакансии"
        :allowed-search="false"
        :allowed-create="true"
        :allowed-remove="true"
        :create-route="{ name: 'Job', params: { id: 'new' }}"
        update-route="Job"
        remove-title="Удаление вакансии"
        remove-message="Вы действительно хотите удалить вакансию '<%= item.name %>'?"
    >
        <template v-slot:item.place="{ item }">
            {{ place(item) }}
        </template>
        <template v-slot:item.created_at="{ item }">
            {{ item.createdAt | dateTime }}
        </template>
    </item-list-view>
</template>

<script>
import api from '../../services/jobs'
import ItemListView from '../../components/ItemListView'
import clinicsService from "../../services/clinics"
import find from 'lodash/find'

export default {
  components: { ItemListView },
  beforeRouteEnter (to, from, next) {
    clinicsService.getAll({ limit: 30 })
      .then(({ items }) => {
        next(vm => {
          vm.clinics = items
        })
      }).catch(err => {
      next(false)
    })
  },
  data: () => ({
    api: api,
    clinics: [],
    headers: [
      { text: 'Название', value: 'name' },
      { text: 'Место', value: 'place' },
      { text: 'Дата', value: 'createdAt' },
      { text: '', value: 'action' }
    ]
  }),
  methods: {
    place (item) {
      if (item.clinicId) {
        let clinic = find(this.clinics, { id: item.clinicId })
        if (clinic) {
          return clinic.name
        }
      }
      return item.place
    }
  }
}
</script>
