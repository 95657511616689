import BaseService from './baseService'

class JobService extends BaseService {
  get url () {
    return 'jobs'
  }

  createModel () {
    return {
      name: '',
      uri: '',
      description: '',
      place: null,
      clinicId: null
    }
  }
}

export default new JobService()

